<template>
  <b-card>

    <div
      class="flex-between text-right"
      style="margin-bottom: 10px"
    >
      <div class="flex-start">
        <div class=" filter-item">
          <!--            <span class="input-label">搜索文本</span>-->
          <el-input
            v-model="listQuery.SearchStr"
            placeholder="请输入内容"
            clearable
          />
        </div>
        <el-button
          size="small"
          class="filter-item"
          type="primary"
          icon="el-icon-search"
          @click="getTemplatePage"
        >
          搜索
        </el-button>
      </div>
      <el-button
        style="margin-bottom: 10px;"
        type="primary"
        size="small"
        icon="el-icon-plus"
        @click="$router.push(`/template/content/0`)"
      >
        新增
      </el-button>
    </div>
    <el-table
      v-loading="listLoading"
      fit
      border
      highlight-current-row
      :data="tableData"
      stripe
      style="width: 100%"
    >
      <el-table-column
        prop="templateName"
        label="模版名称"
      />
      <el-table-column
        width="200"
        label="类型"
      >
        <template v-slot="{row}">
          {{ showTemplateType(row) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="description"
        label="模版描述"
      />
      <el-table-column
        label="操作"
        width="200"
      >
        <template slot-scope="{row}">
          <el-link
            :underline="false"
            class="mr10"
            type="primary"
            @click="$router.push(`/template/content/${row.id}`)"
          >
            <el-tooltip
              content="编辑模版内容"
              placement="top"
              effect="light"
            >
              <i class="el-icon-edit-outline" />
            </el-tooltip>
          </el-link>
          <el-link
            :underline="false"
            class="mr10"
            type="primary"
            @click="openSaveDialog(2,row)"
          >
            <el-tooltip
              content="修改"
              placement="top"
              effect="light"
            >
              <i class="el-icon-edit" />
            </el-tooltip>
          </el-link>

          <el-link
            :underline="false"
            type="primary"
            @click="deleteTemplate(row)"
          >
            <el-tooltip
              content="删除"
              placement="top"
              effect="light"
            >
              <i class="el-icon-delete" />
            </el-tooltip>
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.pageSize"
      @pagination="getTemplatePage"
    />


  </b-card>
</template>

<script>
import { DeleteTemplateById, GetTemplateListByPage } from '@/api/knowledgeBase/template'
import { error, success } from '@core/utils/utils'
import pagination from '@core/components/pagination/Pagination'

export default {
  name: 'TemplateList',

  components: {
    pagination,

  },

  data() {
    return {
      queryData: {},
      total: 0,
      tableData: [],
      listLoading: false,
      listQuery: {
        page: 1,
        pageSize: 10,
        SearchStr: '',
      },
    }
  },
  computed: {
    showTemplateType() {
      return function (row) {
        let typeName = ''
        // eslint-disable-next-line default-case
        switch (row.templateType) {
          case 1:
            typeName = '隐私政策'
            break
          case 2:
            typeName = '隐私声明'
            break
          case 3:
            typeName = '制度流程'
            break
        }
        return typeName
      }
    },
  },
  created() {
    this.getTemplatePage()
  },

  methods: {
    getTemplatePage() {
      GetTemplateListByPage(this.listQuery).then(res => {
        if (res.data.code === 0) {
          this.tableData = res.data.data.list
          this.total = res.data.data.total
        }
      })
    },
    deleteTemplate(data) {
      this.$confirm('是否确认删除当前模版吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        DeleteTemplateById({ id: data.id }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            this.getTemplatePage()
            success(res.data.msg)
          } else {
            error(res.data.msg)
          }
        })
      })
    },
    openSaveDialog(type, data) {
      if (type === 2) {
        this.$refs.templateSave.form = data
      }
      this.$refs.templateSave.dialogVisible = true
    },
  },
}
</script>
